import config from "config"
import { useEffect, useState } from "react"
import { Route } from 'react-router-dom';
import { oauth } from 'oauth/oauth'

// affiche si non connecté le layout du login. sinon redirige sur la partie admin
const LoginRoute = ({ component: Component, ...rest }) => {
    let [trust, setTrust] = useState('red')
    useEffect(() => {
        openLoginRoute()
    }, [])
    useEffect(() => {
        if (trust === 'green') {
            let stopRedirect = window.localStorage.getItem('stopRedirect') || false
            if (!!stopRedirect === true) { debugger }
            window.location.href = config.admin.home.url
        }
    }, [trust])
    const openLoginRoute = async () => {
        const isAuthenticated = await oauth.authenticate() // la personne est-elle authentifiée?
        if (isAuthenticated === true)
            setTrust('green') // redirection admin
        else
            setTrust('orange') // affichage du layout login
    }
    if (trust === 'red' || trust === 'green') return null
    if (trust === 'orange') return (<Route {...rest}><Component /></Route>)
}
export default LoginRoute
