import config from "config.js"
import { useRef } from 'react'
import { Route } from 'react-router-dom'
import { mdiGoogleChrome, mdiAppleSafari, mdiFirefox, mdiMicrosoftEdge, mdiOpera } from '@mdi/js';
import { AppBar, Button, Grid, SpeedDial, SpeedDialAction, Toolbar, Typography } from '@mui/material'
import { useState } from 'react'
import { useWidth } from 'components/hooks/UseWidth'
import { useEffect } from 'react'

import { makeStyles } from 'tss-react/mui';

import LoginPage from "views/login/LoginPage"
import LoginWithMagickLinkPage from "views/login/LoginWithMagickLinkPage"
import image from 'assets/img/auth.jpg'
import brandImage from "assets/img/keepaticketGlow.webp"
import ChangePasswordPage from "views/login/ChangePasswordPage"
import CTAKoPage from "views/login/CTAKoPage"
import { CloudSyncOutlined, Info, InfoRounded } from "@mui/icons-material";
import MDIReactIcon from "@mdi/react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles()((theme) => ({
    container: {
        // ...container,
        zIndex: "2",
        position: "relative",
        paddingTop: "20vh",
        color: "#FFFFFF"
    },
    pageHeader: {
        backgroundColor: "#333",
        minHeight: "100vh",
        height: "auto",
        display: "inherit",
        position: "relative",
        margin: "0",
        padding: "0",
        border: "0",
        alignItems: "center",
        "&:before": {
            background: "rgba(0, 0, 0, 0.5)"
        },
        "&:before,&:after": {
            position: "absolute",
            zIndex: "1",
            width: "100%",
            height: "100%",
            display: "block",
            left: "0",
            top: "0",
            content: '""'
        },
        "& footer li a,& footer li a:hover,& footer li a:active": {
            color: "#FFFFFF"
        },
        "& footer": {
            position: "absolute",
            bottom: "0",
            width: "100%",
        }
    },
    appBar: {
        display: "flex",
        border: "0",
        padding: "0.725rem 0",
        marginBottom: "20px",
        width: "100%",
        backgroundColor: "transparent",
        transition: "all 150ms ease 0s",
        alignItems: "center",
        flexFlow: "row nowrap",
        justifyContent: "flex-start",
        position: "fixed",
        zIndex: "500000100"
    },
    brandImage: {
        [theme.breakpoints.down("sm")]: {
            width: '75%'
        }
    },
    tooltipLabel: {
        "&.MuiSpeedDial-root": {
            "& .MuiSpeedDialAction-staticTooltip": {
                "& .MuiSpeedDialAction-staticTooltipLabel": {
                    transform: "translateY(-65px) rotate(-45deg)"
                }
            }
        }
    },
    linkBtn: {
        marginRight: "0.5rem",
        marginLeft: "0.5rem",
        fontSize: "12px",

    },
    AppBarDesktop: {
        display: 'flex'
    }
}))



const LoginLayout = (props) => {
    const width = useWidth()
    let myWidth = width === 'sm' || width === 'xs' ? 'smartphone' : width === 'md' ? 'medium' : 'large'
    const { classes } = useStyles()
    let { ticketToken } = useParams()
    const actions = [
        { icon: <MDIReactIcon path={mdiGoogleChrome} title="chrome" size={1} color="#FCC31E" />, name: "Chrome", operation: "chrome" },
        { icon: <MDIReactIcon path={mdiAppleSafari} title="safari" size={1} color="#006CFF" />, name: "Safari", operation: "safari", },
        { icon: <MDIReactIcon path={mdiMicrosoftEdge} title="edge" size={1} color="#09b4f6" />, name: "Edge", operation: "edge", },
        { icon: <MDIReactIcon path={mdiFirefox} title="firefox" size={1} color="orange" />, name: "Firefox", operation: "firefox", },
        { icon: <MDIReactIcon path={mdiOpera} title="opera" size={1} color="#CB0B1E" />, name: "Opera", operation: "opera", },
    ];
    const [syncDb, setSyncDb] = useState(false);
    function handleClick(e, operation) {
        e.preventDefault();
        if (operation === "chrome") {
            window.open("https://www.google.com/chrome", '_blank')
        } else if (operation === "safari") {
            window.open("https://www.apple.com/safari", '_blank');
        } else if (operation === "edge") {
            window.open("https://www.microsoft.com/fr-FR/edge", '_blank');
        } else if (operation === "firefox") {
            window.open("https://www.mozilla.org/fr-FR/firefox/new", '_blank');
        } else if (operation === "opera") {
            window.open("https://www.opera.com/fr", '_blank');
        }
    }
    const outSideChips = useRef(null);
    const handleClickOutside = (event) => {
        if (outSideChips.current && !outSideChips.current.contains(event.target)) {
            setSyncDb(false);
        }
    };
    const onKeyDownOut = (event) => {
        if (event.key === "Escape") {
            setSyncDb(false);
        }
    };
    useEffect(() => {
        document.addEventListener("keydown", onKeyDownOut, true);
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("keydown", onKeyDownOut, true);
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);
    /* end speedDial*/

    return (
        <>
            <div>
                {myWidth === 'smartphone' ? (
                    <>
                        <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
                            <Toolbar>
                                <SpeedDial
                                    ariaLabel="SpeedDial browser link"
                                    sx={{
                                        position: "fixed",
                                        bottom: 50,
                                        right: 5,
                                    }}
                                    icon={<InfoRounded openIcon={<InfoRounded />} />}
                                    direction="left"
                                    className={classes.tooltipLabel}
                                >
                                    {actions.map((action) => (
                                        <SpeedDialAction

                                            key={action.name}
                                            icon={action.icon}
                                            tooltipTitle={action.name}
                                            tooltipPlacement="bottom"
                                            tooltipOpen
                                            onClick={(e) => {
                                                handleClick(e, action.operation);
                                            }}
                                        />
                                    ))}
                                </SpeedDial>
                                <Typography variant="body2" component="p" sx={{ fontSize: "12px" }}>
                                    Si vous rencontrez des difficultés pour vous connecter,
                                    nous vous invitons à utiliser une version à jour du navigateur
                                </Typography>
                            </Toolbar>
                        </AppBar>


                    </>
                ) : (
                    <AppBar position="static" >
                        <Toolbar className={classes.AppBarDesktop}>
                            <Typography variant="body2" >
                                Si vous rencontrez des difficultés pour vous connecter,
                                nous vous invitons à utiliser une version à jour du navigateur,
                            </Typography>
                            <Button
                                startIcon={<MDIReactIcon path={mdiMicrosoftEdge} title="edge" size={1} color="#09b4f6" />}
                                className={classes.linkBtn}
                                variant={'outlined'}
                                target="_blank"
                                component="a"
                                color="secondary"
                                href="https://www.microsoft.com/fr-FR/edge"
                            >Edge (version 79 ou supérieure)</Button>
                            <Typography variant="body2"> ou un autre navigateur,</Typography>
                            <Button
                                startIcon={<MDIReactIcon path={mdiGoogleChrome} title="chrome" size={1} color="#FCC31E" />}
                                className={classes.linkBtn}
                                variant={'outlined'}
                                target="_blank"
                                component="a"
                                color="secondary"
                                href="https://www.google.com/chrome"
                            >Google Chrome</Button>
                            <Button
                                startIcon={<MDIReactIcon path={mdiFirefox} title="firefox" size={1} color="orange" />}
                                className={classes.linkBtn}
                                variant={'outlined'}
                                target="_blank"
                                component="a"
                                color="secondary"
                                href="https://www.mozilla.org/fr-FR/firefox/new"
                            >Mozilla Firefox</Button>
                            <Button
                                startIcon={<MDIReactIcon path={mdiAppleSafari} title="safari" size={1} color="#006CFF" />}
                                className={classes.linkBtn}
                                variant={'outlined'}
                                target="_blank"
                                component="a"
                                color="secondary"
                                href="https://www.apple.com/safari"
                            >Safari</Button>
                            <Button
                                startIcon={<MDIReactIcon path={mdiOpera} title="opera" size={1} color="#CB0B1E" />}
                                className={classes.linkBtn}
                                variant={'outlined'}
                                target="_blank"
                                component="a"
                                color="secondary"
                                href="https://www.opera.com/fr"
                            >Opera</Button>
                        </Toolbar>
                    </AppBar>
                )}

                <div className={classes.appBar}>
                    <Toolbar >
                        <Button style={{ backgroundColor: 'transparent' }} href={config.front.home.url}>
                            <img
                                alt={"brand"}
                                width={200}
                                className={classes.brandImage}
                                src={brandImage}></img>
                        </Button>
                    </Toolbar>
                </div>
                <div
                    className={classes.pageHeader}
                // style={{
                //     backgroundImage: 'url(' + image + ')',
                //     backgroundSize: 'cover',
                //     backgroundPosition: 'top center'
                // }}
                >
                    <div className={classes.container}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item>
                                <Route path={"/logFromClient/:ticketToken"} exact component={LoginPage} ticketToken={ticketToken}></Route>
                                <Route path={"/login"} exact component={LoginPage}></Route>
                                <Route path={"/loginWithMagickLink/:token"} exact component={LoginWithMagickLinkPage}></Route>
                                <Route path={"/reset/:token"} exact component={() => <ChangePasswordPage type="reset" />}></Route>
                                <Route path={"/choosePasswordFirstTime/:token"} exact component={() => <ChangePasswordPage type="firstTime" />}></Route>
                                <Route path={"/cta/ko"} exact component={CTAKoPage}></Route>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </>
    )
}
export default LoginLayout
