import { Button, LinearProgress, Stack, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { useSnackbar } from "notistack"
import { restIO } from "utils/io"
import { useHistory, useParams } from "react-router-dom"
import config from "config"
import { deleteAllCookies } from "utils/cookie"
import { removeServiceWorker } from "utils/serviceWorker"
import { makeStyles } from "tss-react/mui"
const useStyles = makeStyles()((theme) => ({
    card: {
        border: "0",
        marginBottom: "30px",
        marginTop: "30px",
        borderRadius: "6px",
        color: "rgba(0, 0, 0, 0.87)",
        background: "#fff",
        width: "100%",
        boxShadow:
            "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        minWidth: "0",
        wordWrap: "break-word",
        fontSize: ".875rem",
        transition: "all 300ms linear"
    }
}))
const LoginWithMagickLinkPage = (props) => {
    const { classes } = useStyles()
    const history = useHistory()

    const { ...params } = useParams()
    const { enqueueSnackbar } = useSnackbar()
    useEffect(() => {
        setTimeout(() => handleLoginWithMagickLink(), 1000) // pour voir la suberbe anim:)
    }, [])


    const handleLoginWithMagickLink = async (userLogin, userPassword) => {
        removeServiceWorker() // remove service worker cache
        deleteAllCookies(["kmSession"]) // remove cookies
        window.localStorage.removeItem('JWT') // remove JWT 
        let oauthLoginUrl = config.oauth.loginWithMagickLink.url
        let responseKeepapi = await restIO({ method: 'post', endpoint: oauthLoginUrl, payload: { token: params.token } }) // fecth oauth login url
        // at this time cookie are stored in browser
        let { datas, extraData, details, code, error } = responseKeepapi
        let loggedIn = false
        if (code == 'ok') {
            let data = datas[0]
            if (data.auth === true && data.token !== '') { // you are logged !!!
                window.localStorage.setItem('JWT', data.token)
                window.localStorage.setItem('refresh', data.refresh)
                loggedIn = true
            }
        }
        if (loggedIn === true) {
            // prepare autolog url for admin
            let autologUrl = config.admin.autolog.url
            autologUrl = autologUrl.replace('___TOKEN___', window.localStorage.getItem('JWT'))
            window.location.href = autologUrl // let's go to autolog at admin
        }
        else {
            let message = "La plateforme n'arrive pas à vous identifier. Le lien magique n'a pas fonctionné"
            enqueueSnackbar(message, { variant: 'error' })
            setTimeout(() => history.push('/login'), 4000)

            return
        }
    }
    return (
        <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
            <b>Bienvenue</b>
            <br />
            La plateforme est en train de vous connecter
            <LinearProgress color="secondary" />
            <LinearProgress color="success" />
            <LinearProgress color="inherit" />
        </Stack>

    )
}


export default LoginWithMagickLinkPage