import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import WorkInProgressView from 'views/workInProgressView/WorkInProgressView'
import config from 'config'
import {restIO} from "./utils/io";
let doMaintenance = false;


const apiEnableMaintenance = async () => {
  let apiEnableMaintenanceUrl = config.api.public.maintenance.url
  let responseKeepapi = await restIO({method: 'get', endpoint: apiEnableMaintenanceUrl}) // fecth /public/login/apiMagickLinkCommunicationUrl
  let {datas, extraData, details, code, error, message = "", showBackendMessage = false} = responseKeepapi

  if (!!responseKeepapi.enableMaintenance === true) {
    doMaintenance = true
    if (window.localStorage && !!(window.localStorage.getItem('flagByPassMaintenance')) === true) {
      doMaintenance = false
    }

  }
  if (!!doMaintenance === true)
    ReactDOM.render(<WorkInProgressView />, document.getElementById('root'))
  else
    ReactDOM.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>,
        document.getElementById('root')
    );

}
apiEnableMaintenance()


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
