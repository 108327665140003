import { useEffect } from "react"
import { useHistory } from "react-router-dom"

const CTAKoPage = () => {
    const history = useHistory()

    useEffect(() => {
        setTimeout(() => history.push('/logout'), 4000)
    }, [])
    return (
        <>
            le lien a expiré
        </>
    )
}

export default CTAKoPage