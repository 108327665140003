import React from 'react'
import workInProgressImg from 'assets/img/workInProgress.png'
import { Grid } from '@mui/material'
import styles from './styles'
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => styles)
const WorkInProgressView = ({
    ...props
}) => {
    const { classes } = useStyles()
    const maintenance = () => {
        window.localStorage.setItem('flagByPassMaintenance',true)
        window.location.href="/"
    }
    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
        >
            <Grid item>
                <img src={workInProgressImg} alt="work in progress by keepapi team" />
            </Grid>
            <Grid item>
                <div className={classes.keepapi}>
                    La plateforme est en maintenance pour quelques minutes .<span onClick={() => maintenance()}>.</span>.
                    <br />
                    Merci de votre compréhension.
                    <br />
                    <b className={classes.golden}>L'équipe {'{'} Keep : Api {'}'}</b>
                </div>
            </Grid>
        </Grid>
    )
}
export default WorkInProgressView