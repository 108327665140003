import config from '../config'
import { restIO } from 'utils/io'

const fetchIsAuthenticated = async () => {
    const oauthIsAuthenticatedUrl = config.oauth.isAuthenticated.url
    let responseKeepapi = await restIO({ jwt: true, endpoint: oauthIsAuthenticatedUrl })
    let { datas, extraData, details, code, error } = responseKeepapi
    if (code !== 'ok') return false
    if (datas.length !== 1) return false
    return datas[0].auth
}
const oauth = {
    async authenticate() {
        const isAuthenticated = await fetchIsAuthenticated()
        return isAuthenticated
    },
    signout(cb) {
        setTimeout(cb, 100)
    }
}
export { oauth }