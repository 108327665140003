// const gitInfo = process.env.NODE_ENV !== 'production' ? GitInfo() : {}
const config = {
	maintenance: process.env.REACT_APP_MAINTENANCE || false,
	app: {
		serviceName: `${process.env.REACT_APP_APP_SUBDOMAIN || "callWF"}.${process.env.REACT_APP_MAIN_DOMAIN_NAME || "127.0.0.1"
			}`,
		env: process.env.REACT_APP_ENV || "local",
		debugMode: process.env.REACT_APP_DEBUG_MODE || !!window.localStorage.getItem('debugMode') === true || false,
	},
	domainName: process.env.REACT_APP_DOMAIN_NAME,
	securityPolicy: {
		saltRound: 10,
		patternUserPassword: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-.'"+~:€£{[|\\^\]}%!?@#$%^&*;,_°])(?=.{8,})*[^\s]+$/,
		patternUserPasswordText: `Le mot de passe doit comporter au moins 8 caractères dont un chiffre, une minuscule, une majuscule, et au moins un caractère spécial de type -+~:€£{[|]}%!?@#$%^&*;,_°"}`,
		patternUserConfirmPasswordText: `Les mots de passes doivent être identiques.`
	},
	socialNetwork: {
		google: {
			clientId: "207934227074-b4ju481ob8fcijjo4al7sv4tcej6mae5.apps.googleusercontent.com"
		}
	},
	front: {
		home: {
			url: `${process.env.REACT_APP_FRONT_URL_PUBLIC}`
		},
	},
	admin: {
		home: {
			url: `${process.env.REACT_APP_ADMIN_URL_PUBLIC}`
		},
		autolog: {
			url: `${process.env.REACT_APP_ADMIN_URL_PUBLIC}/autolog/___TOKEN___`
		},
	},

	api: {
		public: {
			login: {
				getTransactionalRouteUrl: {
					url: `${process.env.REACT_APP_API_URL_PUBLIC}/api/public/login/getTransactionalRoute`
				},
				magickLinkCommunication: {
					url: `${process.env.REACT_APP_API_URL_PUBLIC}/api/public/login/magickLinkCommunication`
				}
			},
			maintenance: {
				url: `${process.env.REACT_APP_API_URL_PUBLIC}/maintenance`
			}
		}
	},
	oauth: {
		probe: {
			url: `${process.env.REACT_APP_OAUTH_URL_PUBLIC}/probe`
		},
		logout: {
			url: `${process.env.REACT_APP_OAUTH_URL_PUBLIC}/oauth/logout`
		},
		login: {
			url: `${process.env.REACT_APP_OAUTH_URL_PUBLIC}/oauth/login`
		},
		loginWithMagickLink: {
			url: `${process.env.REACT_APP_OAUTH_URL_PUBLIC}/oauth/loginWithMagickLink`
		},
		logFromClient: {
			url: `${process.env.REACT_APP_OAUTH_URL_PUBLIC}/oauth/logFromClient`
		},
		loginWithGoogle: {
			url: `${process.env.REACT_APP_OAUTH_URL_PUBLIC}/oauth/loginWithGoogle`
		},
		register: {
			url: `${process.env.REACT_APP_OAUTH_URL_PUBLIC}/oauth/register`
		},
		isAuthenticated: {
			url: `${process.env.REACT_APP_OAUTH_URL_PUBLIC}/oauth/isAuthenticated`
		}
	},
	pf: {
		public: {
			url: `${process.env.REACT_APP_PF_URL_PUBLIC}`,
			user: {
				changePassword: {
					url: `${process.env.REACT_APP_PF_URL_PUBLIC}/pf/public/user/changePassword`,
				}
			},
		},
	}
};

export default config;
