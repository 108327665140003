import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import LoginLayout from 'layouts/LoginLayout'
import LoginRoute from 'oauth/LoginRoute'
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import theme from 'assets/jss/defaultTheme';
import createCache from '@emotion/cache';
import { CacheProvider } from "@emotion/react";
import NotLogged from 'oauth/NotLogged';
export const muiCache = createCache({
  'key': 'mui',
  'prepend': true,
});
function App() {
  return (
    <>
      <CacheProvider value={muiCache}>
        <SnackbarProvider maxSnack={3}>
          <CssBaseline />
          <ThemeProvider theme={theme}>
            <Router>
              <Switch>
                <LoginRoute path="/login" component={LoginLayout} />
                <Route path={"/cta/ko"} exact component={LoginLayout}></Route>
                <Route path={"/logFromClient/:ticketToken"} exact component={LoginLayout}></Route>
                <Route path={"/reset/:token"} exact component={LoginLayout}></Route>
                <Route path={"/choosePasswordFirstTime/:token"} exact component={LoginLayout}></Route>
                <Route path={"/loginWithMagickLink/:token"} exact component={LoginLayout}></Route>
                <Route path="/logout" component={NotLogged} />
                <Redirect to="/login"></Redirect>
              </Switch>
            </Router>
          </ThemeProvider>
        </SnackbarProvider>
      </CacheProvider>
    </>
  );
}
export default App;
